<template>
    <Page :title="title" icon="mdi-view-quilt">
        <v-container style="min-width:1200px;" text-center v-if="company">
            <div align="left">{{ company.QBCompanyId }}</div>
            <v-switch v-model="isResyncFromLegacy" label="Resync from Legacy?"></v-switch>
            <v-switch v-model="isWipeLocalQB" label="Wipe Local QB Collection?"></v-switch>

            <action-flow-step
                :title="steps[step].title"
                :runLabel="steps[step].runLabel"
                :AFId="company[`${step}AFId`]"
                :step="step"
                :runStep="runStep(step)"
                v-for="step in Object.keys(steps)"
                :disableAll="disableAll"
                :key="step"
            />
        </v-container>
        <v-layout slot="actions">
            <btn preset="done" @click="goBack" small />
            <btn preset="edit" @click="editDetails" small />
            <v-spacer></v-spacer>
            <btn color="error" @click="confirmDelete" small>Delete Company!</btn>
            <Confirm ref="confirm"></Confirm>
        </v-layout>
    </Page>
</template>

<script>
import api from '@/api';

import ActionFlowStep from '@/components/common/ActionFlowStep';
import Confirm from '@/components/form/buttons/Confirm';

const steps = {
    step1: { title: 'Step 1 - Company', runLabel: '' },
    step2: { title: 'Step 2 - Cost Centres', runLabel: '' },
    step3: { title: 'Step 3 - Customers', runLabel: '' },
    step4: { title: 'Step 4 - Vendors', runLabel: '' },
    step5: { title: 'Step 5 - Accounts', runLabel: '' },
    step6: { title: 'Step 6 - Items', runLabel: '' },
    // step7: { title: 'Step 7 - Cust Opening Bal', runLabel: '' },
    // step8: { title: 'Step 8 - Vend Opening Bal', runLabel: '' },
    // step9: { title: 'Step 9 - Account Opening Bal', runLabel: '' },
};

export default {
    name: 'CompanySync',
    components: { ActionFlowStep, Confirm },
    data() {
        return {
            steps,
            id: this.$route.params.id,
            company: null,
            dataLoaded: false,
            disableAll: false,
            isResyncFromLegacy: false,
            isWipeLocalQB: false,
        };
    },
    computed: {
        title() {
            return this.company && this.company.companyName ? this.company.companyName : '';
        },
    },
    methods: {
        async getCompany() {
            const result = await api.get(this.$store, `company/${this.id}`);
            this.company = { ...result.data };
        },
        goBack() {
            this.$router.push(`/auth/admin/company`);
        },
        editDetails() {
            this.$router.push(`/auth/admin/company/${this.id}`);
        },
        runStep(step, resumeRootId) {
            return async () => {
                try {
                    this.disableAll = true;
                    const result = await api.post(this.$store, `qbsync/${step}`, {
                        companyId: this.id,
                        resumeRootId,
                        isResyncFromLegacy: this.isResyncFromLegacy,
                        isWipeLocalQB: this.isWipeLocalQB,
                    });

                    if (result.data && result.data.resumeRootId) {
                        this.steps[step].runLabel = result.data.breakDescription;
                        return await this.runStep(step, result.data.resumeRootId)();
                    }
                    await this.getCompany();
                } catch (e) {
                    await this.$store.dispatch('setError', e.message);
                }
                this.steps[step].runLabel = '';
                this.disableAll = false;
            };
        },
        async confirmDelete() {
            if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
                console.log('about to delete all data for companyId', this.id);
                this.$store.dispatch('setLoading', `Deleting`);
                const { data } = await api.post(this.$store, `qb/wipecompany`, {
                    companyId: this.id,
                });
                this.company = data.result.company;
                this.$store.dispatch('setLoading');
            }
        },
    },
    created() {
        this.getCompany();
    },
};
</script>

<style scoped></style>
